import React, { useEffect, useState } from 'react';
// @material-ui/core
import { Box, CircularProgress, Grid, IconButton, List, makeStyles, Tooltip, Typography } from '@material-ui/core';
// icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HardwareIcon from '@mui/icons-material/Hardware';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SpokeIcon from '@mui/icons-material/Spoke';
import WifiIcon from '@mui/icons-material/Wifi';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
// components
import TopNav from '../../common/TopNav';
// ui
import Container from '../../Elements/Container';
// custom hooks
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import StateNode from './StateNode';
// endpoints
import { green, red } from '@material-ui/core/colors';
import { ArrowBackIos } from '@mui/icons-material';
import { getStatus } from '../../../services/meters';
import { getNodesTree } from '../../../services/hierarchy';
//Helpers
import { returnEmpty } from '../../../helpers/common';

let scrollsSettings = {
  overflow: "scroll",
  height: "90vh",
  overflowX: "hidden",
}
let webkitScrollbar = {
  background: "white",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "#beb1b18f",
  color: "red",
  width: "9px"
}
let webkitscrollbarTrack = {
  background: "#dfdfe957",
}

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    display: "flex",

  },
  leftContainer: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    //son los estilos de las barras scroll en una variable.
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    "@media (max-width: 1280px)": {
      width: "100%",
    },
  },
  rightContainer: {
    // borderLeft: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(2),
    //son los estilos de las barras scroll en una variable
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      ...webkitScrollbar
    },
    '&::-webkit-scrollbar-track': {
      ...webkitscrollbarTrack
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1)
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 23,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 16,
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  value: {
    fontSize: 14,
    color: theme.palette.grey[600]
  }
}));

function State() {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect(1279);
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState([])

  const [selectedNode, setSelectedNode] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({})

  const [showLeftBlock, setShowLeftBlock] = useState(true);
  const [showRightBlock, setShowRightBlock] = useState(true)

  useEffect(() => {
    if (isMobile) {
      // console.log("mobile")
      setShowRightBlock(false)
    }
    else {
      // console.log("desktop");
      setShowLeftBlock(true)
      setShowRightBlock(true)
    }
  }, [isMobile])

  //Funcion q trae el arbol de nodos
  const fetchNodesTreeData = async () => {
    try {
      const res = await getNodesTree();
      setNodes(res.data)
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        setLoading(true);
        const res = await getStatus();
        setStatus(res.data);
        setLoading(false);
      }
      catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchNodesTreeData()
    fetchStatus()
  }, []);

  const handleBackPageClick = () => {
    setShowLeftBlock(true)
    setShowRightBlock(false)
    setSelectedNode({});
  }

  const handleSelectedNode = node => {
    setSelectedNode(node);
    const nodeStatus = status.find(n => n.node_id === node.id) || {}
    setSelectedStatus(nodeStatus)
    if (isMobile) {
      setShowRightBlock(true)
      setShowLeftBlock(false)
    }
  };

  return (
    <>
      <TopNav titulo={'Dashboards'} subtitulo={"| Estado"} />

      <Container >
        {loading &&
          <Box display='flex' justifyContent='center' mt={2}>
            <CircularProgress />
          </Box>
        }

        {!loading &&
          <Grid className={classes.root} container>
            {showLeftBlock &&
              <Grid item xs={12} lg={7} className={classes.leftContainer}>
                <List>
                  {nodes.map(node =>
                    <StateNode node={node} handleSelectedNode={handleSelectedNode} selectedNode={selectedNode} key={node.id} status={status} />
                  )}
                </List>
              </Grid>
            }

            {showRightBlock &&
              <Grid item xs={12} lg={5} className={classes.rightContainer}>
                {isMobile &&
                  <IconButton onClick={handleBackPageClick}>
                    <ArrowBackIos />
                  </IconButton>
                }

                {selectedNode.level_nombre &&
                  <>
                    <Box display='flex' alignItems='center' className={classes.titleContainer}>
                      {
                        selectedStatus?.alert &&
                        <Box>
                          <Tooltip title={`${selectedNode.nombre} tiene alertas`}>
                            <ReportProblemIcon style={{ color: 'white' }} />
                          </Tooltip>
                        </Box>
                      }
                      <Typography variant='h2' className={classes.title}>
                        <Box ml={1}>{selectedNode.nombre}</Box>
                      </Typography>
                    </Box>

                    <Box display='flex' alignItems='center' className={classes.subtitle} mt={1} py={1} px={0.5}>
                      <HardwareIcon />
                      <Box ml={0.5}>
                        Hardware
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Dispositivo:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.product)}
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Descripción:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.description)}
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Número de serie:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.product_code)}
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Device ID:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.mac)}
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Firmware:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.firmware)}
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Ubicación:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.ubicacion)}
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Nombre:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.meter)}
                      </Box>
                    </Box>
                    {
                      !selectedStatus?.is_owner &&
                      <Box mt={0.5}>
                        <Box className={classes.label} display="inline">
                          Propietario:
                        </Box>
                        <Box display="inline" ml={1} className={classes.value}>
                          {returnEmpty(selectedStatus?.owner)}
                        </Box>
                      </Box>
                    }

                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Fecha de alta:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.registration)}
                      </Box>
                    </Box>

                    <Box display='flex' alignItems='center' className={classes.subtitle} mt={1} py={1} px={0.5}>
                      <SpokeIcon />
                      <Box ml={0.5}>
                        Estado
                      </Box>
                    </Box>
                    <Box mt={0.5}>

                      <Box display="inline" className={classes.value}>
                        {selectedStatus?.online ? <Box display='flex' alignItems='center'><FiberManualRecordIcon style={{ color: green[400], fontSize: '14px' }} /> Online</Box> : <Box display='flex' alignItems='center'><FiberManualRecordIcon style={{ color: red[400], fontSize: '14px' }} /> Offline</Box>}
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Inicio:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.last_boot)}
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      <Box className={classes.label} display="inline">
                        Último apagado:
                      </Box>
                      <Box display="inline" ml={1} className={classes.value}>
                        {returnEmpty(selectedStatus?.last_shutdown)}
                      </Box>
                    </Box>

                    {selectedStatus?.wifi &&
                      <>
                        <Box display='flex' alignItems='center' className={classes.subtitle} mt={1} py={1} px={0.5}>
                          <WifiIcon />
                          <Box ml={0.5}>
                            Conectividad Wifi
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Intesidad de señal:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {selectedStatus?.wifi?.quality || 0} %
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Intesidad de señal (dbm):
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {selectedStatus?.wifi?.dbm || 0} dbm
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            SSID:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.wifi?.ssid)}
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            BSSID:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.wifi?.bssid)}
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            IP:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.wifi?.ip)}
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Dirección MAC:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.wifi?.mac)}
                          </Box>
                        </Box>
                      </>
                    }

                    {selectedStatus?.mobile &&
                      <>
                        <Box display='flex' alignItems='center' className={classes.subtitle} mt={1} py={1} px={0.5}>
                          <TapAndPlayIcon />
                          <Box ml={0.5}>
                            Conectividad Mobile
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Intensidad de señal:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {selectedStatus?.mobile?.dbm || 0} dbm
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Intensidad de señal:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {selectedStatus?.mobile?.quality || 0} %
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Modo:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.mobile?.system_mode)}
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Red:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.mobile?.network)}
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            IMEI:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.mobile?.imei)}
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            SIM CCID:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.mobile?.ccid)}
                          </Box>
                        </Box>
                      </>
                    }

                    {selectedStatus?.ethernet &&
                      <>
                        <Box display='flex' alignItems='center' className={classes.subtitle} mt={1} py={1} px={0.5}>
                          <SignalCellular0BarIcon />
                          <Box ml={0.5}>
                            Conectividad Ethernet
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            IP:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.ethernet?.ip)}
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Dirección MAC:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {returnEmpty(selectedStatus?.ethernet?.mac)}
                          </Box>
                        </Box>
                      </>
                    }

                    {selectedStatus?.powermate &&
                      <>
                        <Box display='flex' alignItems='center' className={classes.subtitle} mt={1} py={1} px={0.5}>
                          <img
                            src="/images/logo2.svg"
                            alt="logo"
                            style={{ width: '100px',margin:5 }}
                          />
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Disponibilidad:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {selectedStatus?.powermate?.availability || '-'}
                          </Box>
                        </Box>
                        <Box mt={0.5}>
                          <Box className={classes.label} display="inline">
                            Integridad de datos:
                          </Box>
                          <Box display="inline" ml={1} className={classes.value}>
                            {selectedStatus?.powermate?.data_integrity || '-'}
                          </Box>
                        </Box>
                      </>
                    }
                  </>
                }
              </Grid>
            }
          </Grid>
        }
      </Container>
    </>
  )
}
export default State